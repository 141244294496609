<template>
  <div class="newsInfo" v-show="isOut">
    <div class="newsInfomation">
      <div class="box m160">
        <div class="content1">
          <router-link to="/news/list">
            <svg
              t="1655284142230"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2370"
              data-spm-anchor-id="a313x.7781069.0.i6"
              width="12"
              height="12"
            >
              <path
                d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                p-id="2371"
                fill="#97c852"
              ></path>
            </svg>
            返回列表
          </router-link>
        </div>
        <div class="content2">
          <p>
            {{ archivesInfo.title }}
          </p>
        </div>
        <div class="content3">
          {{ (archivesInfo.add_time * 1000) | dateFmt("YYYY.MM.DD") }}
        </div>
        <div class="content4" v-html="archivesInfo.content"></div>
      </div>
    </div>

    <div class="aft_title p80">
      <div class="box">
        <p>Recommended</p>
        <div>推荐新闻</div>
      </div>
    </div>
    <div class="newsList1">
      <div class="box m160">
        <div class="list" v-for="(item, index) in newsTopList" :key="index">
          <router-link :to="'/news/newsInfo?id=' + item.aid">
            <div class="flexBetween">
              <div class="boxLeft flexStart">
                <div>{{ (item.add_time * 1000) | dateFmt("DD") }}</div>
                <p>{{ (item.add_time * 1000) | dateFmt("YYYY.MM") }}</p>
              </div>
              <div class="boxCenter">
                {{ item.title }}
              </div>
              <div class="boxRight flexEnd">
                <p>
                  <svg
                    t="1655284142230"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="2370"
                    data-spm-anchor-id="a313x.7781069.0.i6"
                    width="14"
                    height="14"
                  >
                    <path
                      d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                      p-id="2371"
                      fill="#ffffff"
                    ></path>
                  </svg>
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="newsMore flexCenter">
      <div class="index_link">
        <router-link to="/news/list"><span>全部新闻</span></router-link>
      </div>
    </div>
    <!-- <SidebarLpk :isSidebar="isSidebar" /> -->
  </div>
</template>

<script>
// import SidebarLpk from "@/views/SidebarLpk.vue";
export default {
  name: "Newsinfo",
  components: {
    // SidebarLpk,
  },
  data() {
    return {
      isOut: false,
      isHeader: true,
      newsTopList: [],
      archivesInfo: {
        title: "",
      },
    };
  },
  created() {
    this.$http
      .get(
        "api.php/Webinfo/newsShow",
        {
          params: {
            aid: this.$route.query.id,
          },
        },
        {
          emulateJSON: true,
        }
      )
      .then(
        function (res) {
          const news_info_data = JSON.parse(res.data);
          this.newsTopList = news_info_data.newsTopList;
          this.archivesInfo = news_info_data.archivesInfo;
          this.isOut = true;
          // console.log(news_info_data);
        },
        function (res) {
          console.log(res.status);
        }
      );
  },
  methods: {
    getPath() {
      this.$http
        .get(
          "api.php/Webinfo/newsShow",
          {
            params: {
              aid: this.$route.query.id,
            },
          },
          {
            emulateJSON: true,
          }
        )
        .then(
          function (res) {
            const news_info_data = JSON.parse(res.data);
            this.newsTopList = news_info_data.newsTopList;
            this.archivesInfo = news_info_data.archivesInfo;
            this.isOut = true;
          },
          function (res) {
            console.log(res.status);
          }
        );
      window.scrollTo({
        top: 0,
        // behavior: "smooth",
      });
    },
  },
  watch: {
    // $route: "getPath",
    $route(to, from) {
      if (to.name == "NewsInfo") {
        this.getPath();
      }
    },
  },
};
</script>

<style lang="less" scoped>
@gilroy_b: "gilroy_bold";
@gilroy_r: "gilroy_regular";
@albb_b: "albb_b";
@albb_r: "albb_r";
@albb_l: "albb_l";
@albb_m: "albb_m";
@color: #97c852;
.newsInfomation {
  background: #f5f5f5;
  margin-top: 0.73rem;
  padding-top: 0.9rem;
  .box {
    .content1 {
      font-size: 0.16rem;
      a {
        color: #666;
        transition: 0.4s;
        svg {
          transform: rotate(180deg);
        }
        &:hover {
          color: @color;
        }
      }
    }
    .content2 {
      margin-top: 0.5rem;
      p {
        font-family: @albb_m;
        font-size: 0.32rem;
        line-height: 0.48rem;
        width: 60%;
      }
    }
    .content3 {
      font-family: @gilroy_r;
      font-size: 0.2rem;
      color: #999;
      text-align: right;
      margin-top: -0.3rem;
    }
    .content4 {
      font-size: 0.18rem;
      font-family: @albb_m;
      color: #666;
      line-height: 0.48rem;
      text-align: justify;
      border-top: 1px solid #ddd;
      margin-top: 0.68rem;
      padding-top: 0.68rem;
      padding-bottom: 0.8rem;
      img {
        width: 100%;
      }
    }
  }
}
.newsList1 {
  .box {
    .list {
      padding: 0.78rem 0;
      border-bottom: 1px solid #ddd;
      a {
        display: block;
        .boxLeft {
          width: 20%;
          font-family: @gilroy_r;
          line-height: 0.6rem;
          padding-top: 0.12rem;
          div {
            font-size: 0.52rem;
            color: #333;
            margin-right: 0.32rem;
          }
          p {
            font-size: 0.22rem;
            color: #999;
          }
        }
        .boxCenter {
          font-family: @albb_m;
          font-size: 0.26rem;
          color: #000;
          line-height: 0.4rem;
          width: 50%;
          text-align: justify;
          transition: 0.4s;
        }
        .boxRight {
          width: 30%;
          p {
            width: 0.6rem;
            height: 0.6rem;
            line-height: 0.6rem;
            text-align: center;
            border-radius: 50%;
            background: @color;
            display: flex;
            svg {
              margin: auto;
            }
          }
        }
        &:hover {
          .boxCenter {
            color: @color;
          }
        }
      }
    }
  }
}
.newsMore {
  margin: 0.8rem 0;
}
@media screen and(max-width:768px) {
  .aft_title .box div {
    font-size: 0.36rem;
  }
  .newsList .box .list a .boxLeft {
    width: 100%;
  }
  .newsList .box .list a .boxRight {
    width: 100%;

    padding-left: 0;
  }

  .newsList .box .list a .boxRight .content3 {
    height: auto;
  }
  .newsList1 .box .list a .boxLeft {
    display: none;
  }
  .newsList1 .box .list a .boxCenter {
    width: 74%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-align: justify;
    text-justify: distribute-all-lines;
  }
  .newsList1 .box .list a .boxRight {
    width: 16%;
  }
  .newsList1 .box .list {
    padding: 0.48rem 0;
  }
  .newsInfomation .box .content2 p {
    font-size: 0.25rem;
    width: 75%;
  }
  .newsInfomation .box .content1 a svg {
    position: relative;
    top: 1px;
  }
  .newsInfomation {
    padding-top: 0.5rem;
    margin-top: 0;
  }
  .newsInfo {
    margin: 60px 0 0;
  }
  .newsInfomation .box .content1 {
    font-size: 0.2rem;
  }
}

@media screen and(max-width:669px) {
  .newsInfomation .box .content1 {
    font-size: 0.25rem;
  }
}
</style>
